/**
 * FILE		   	:	  HOME
 * DISPATCH		:	  NIL
 * METHOD   	:   
 * C-DATE   	:   26_01_22
 * S-DATE   	:   26-01-22
*/

import React, { useEffect, useState, useRef } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import Countdown from "react-countdown";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Button } from "@material-ui/core";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ReactHtmlParser from 'react-html-parser';

import { useSelector, useDispatch } from 'react-redux'

// FILES
import { Account_Connect } from "actions/redux/action";
import TokenCard from '../views/separate/TokenCard'
import axios from 'axios';
import config from '../lib/config';
import {
  CollectiblesList_Home,
  NewCollectiblesList_Home,
  topCreatorsApi,
  getCreatorsCount
} from '../actions/v1/token';
import bannerline from "assets/images/bannerline.png";
import bannerwhiteline from "assets/images/bannerwhiteline.png";
import { CancelOrderRef } from './separate/CancelOrderRef';
import { LikeRef } from './separate/LikeRef';
import { BurnRef } from './separate/BurnRef';
import { PutOnSaleRef } from './separate/PutOnSaleRef';
import { PurchaseNowRef } from './separate/PurchaseNowRef';
import { ReportNowRef } from './separate/ReportNowRef';
import { ShareNowRef } from './separate/ShareNowRef';
import { getcmslistinhome, getpromotion } from '../actions/v1/report'
import isEmpty from '../lib/isEmpty'
import Avatars from "./Avatar";



let toasterOption = config.toasterOption;
const dashboardRoutes = [];
const useStyles = makeStyles(styles);

export default function Home(props) {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1

  };

  var state = {
    responsive: {
      0: {
        items: 1,
      },
      575: {
        items: 2,
      },
      992: {
        items: 3,
      },
      1200: {
        items: 3,
      },
    },

    responsiveone: {
      0: {
        items: 1,
      },
      575: {
        items: 1,
      },
      992: {
        items: 1,
      },
      1200: {
        items: 1,
      },
    },


  };

  const classes = useStyles();
  const history = useHistory();
  const Wallet_Details = useSelector(state => state.wallet_connect_context);
  const dispatch = useDispatch();
  const { ...rest } = props;
  var PutOnSaleForwardRef = useRef();
  var PurchaseNowForwardRef = useRef();
  var BurnForwardRef = useRef();
  var CancelOrderForwardRef = useRef();
  var ReportForwardRef = useRef();
  var LikeForwardRef = useRef();
  var ShareForwardRef = useRef();
  var PlaceABidForwardRef = useRef();
  const [topcreatorsection, settopcreatorsection] = React.useState([]);
  const [LikedTokenList, setLikedTokenList] = useState([]);
  const [Categorylist, setCategorylist] = useState([]);
  const [TokenList, setTokenList] = useState([]);
  const [CatName, setCatName] = useState('All');
  const [CatBasedTokenList, setCatBasedTokenList] = useState({ 'loader': false, 'All': { page: 1, list: [], onmore: true } });
  const [Page, setPage] = useState(1);
  const [tokenCounts, Set_tokenCounts] = useState(0);
  const [tokenCounts_Detail, Set_tokenCounts_Detail] = useState({});
  const [MyTokenBalance, Set_MyTokenBalance] = useState(0);
  const [Bids, Set_Bids] = useState([]);
  const [AccepBidSelect, Set_AccepBidSelect] = useState(0);
  const [tokenBidAmt, Set_tokenBidAmt] = useState(0);
  const [NoOfToken, Set_NoOfToken] = useState(1);
  const [ValidateError, Set_ValidateError] = useState({});
  const [TokenBalance, Set_TokenBalance] = useState(0);
  const [YouWillPay, Set_YouWillPay] = useState(0);
  const [YouWillPayFee, Set_YouWillPayFee] = useState(0);
  const [YouWillGet, Set_YouWillGet] = useState(0);
  const [BidApply_ApproveCallStatus, Set_BidApply_ApproveCallStatus] = useState('init');
  const [BidApply_SignCallStatus, Set_BidApply_SignCallStatus] = useState('init');
  const [AllowedQuantity, Set_AllowedQuantity] = useState({});
  const [Time_Auction_List, Set_Time_Auction_List] = useState([]);
  const [Recent_Nft_List, Set_Recent_Nft_List] = useState([]);
  const [Promotion_List, Set_Promotion_list] = useState([])
  const [Creatorcount, setCreatorcount] = useState(0);
  const [BuyOwnerDetailFirst, Set_BuyOwnerDetailFirst] = useState({});
  const [MyTokenDetail, Set_MyTokenDetail] = useState({});
  const [getcmslistinhome1, setgetcmslistinhome1] = useState({})
  const [getcmslistinhome12, setgetcmslistinhome12] = useState({})
  const [TopSaleValue, setTopSaleValue] = useState({})
  const [home_special_category, set_home_special_category] = useState([])
  const [noOfCreators,setNoOfCreators] = useState(0)

  useEffect(() => {
    getInit();
  }, [])

  function ScrollToTopOnMount() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
    return null;
  }
  

  const Refresh_page = (payload) => {
    //console.log("playload",Wallet_Details,payload)
    //console.log("playload",payload)
    setTokenList([])
    CatBasedTokenList['All'].list = [];
    setCatBasedTokenList(CatBasedTokenList);
    Set_Time_Auction_List([])
    TokenListCall(payload.explore);
  }

  const getcmslistinhomes = async () => {
    var reqdata = {
      load: 'home'
    }
    var convers = await getcmslistinhome(reqdata);
    if (convers && convers.data) {
      setgetcmslistinhome1(convers.data)
    }
  }
  const getcmslistinhomes1 = async () => {
    var reqdata = {
      load: 'tophome'
    }
    var convers = await getcmslistinhome(reqdata);
    if (convers && convers.data) {
      setgetcmslistinhome12(convers.data)
    }
  }
  const promotionData = async () => {
    var test = await getpromotion();
    if (test && test.userValue) {
      Set_Promotion_list(test.userValue)
    }
  }



  async function getInit() {
  
    CategoryListCall();
    TokenListCall();
  


  }

  




 



  async function CategoryListCall() {
    axios
      .get(`${config.vUrl}/token/category/list`)
      .then(response => {
        if (response && response.data && response.data.list) {
          setCategorylist(response.data.list);
          var CatData = response.data.list.filter(item => {
            if (item.show == true)
              return item
          })
          set_home_special_category(CatData)
        }
      })
      .catch(e => console.log(e))
  }

  const onLoadMore = () => {
    CatBasedTokenList[CatName].page = CatBasedTokenList[CatName].page + 1;
    setCatBasedTokenList(CatBasedTokenList);

    TokenListCall({
      page: CatBasedTokenList[CatName].page + 1
    });
  }

  async function catChange(name) {
    if (name != CatName) {
      setCatName(name);
      if (typeof CatBasedTokenList[name] == 'undefined') {
        CatBasedTokenList[name] = { page: 1, list: [], onmore: true };
        setCatBasedTokenList(CatBasedTokenList);
        TokenListCall({ CatName: name, page: 1 });
      }
    }
  }



  async function TokenListCall(data = {}) {
    var currAddr = Wallet_Details.UserAccountAddr
    var name = CatName;
    if (data.CatName) {
      name = data.CatName
    }
    var payload = {
      limit: 8,
      page: (CatBasedTokenList[name] && CatBasedTokenList[name].page) ? CatBasedTokenList[name].page : 1,
      currAddr: currAddr,
      CatName: name,
      from: 'Home',
    }
    CatBasedTokenList.loader = true;
    setCatBasedTokenList(CatBasedTokenList);
    var resp = await CollectiblesList_Home(payload);
    CatBasedTokenList.loader = false;
    setCatBasedTokenList(CatBasedTokenList);

    if (resp && resp.data && resp.data.from == 'token-collectibles-list-home' && resp.data.list && resp.data.list.length > 0) {
      setTokenList(TokenList.concat(resp.data.list));
      setCreatorcount(resp.data.list.length);
      if (typeof CatBasedTokenList[name] == 'undefined') {
        CatBasedTokenList[name] = { page: 1, list: [] };
      }
      CatBasedTokenList[name].list = CatBasedTokenList[name].list.concat(resp.data.list);
      setCatBasedTokenList([]);
      setCatBasedTokenList(CatBasedTokenList);
    }
    else {
      CatBasedTokenList[name].onmore = false;
      setCatBasedTokenList([]);
      setCatBasedTokenList(CatBasedTokenList);
    }


    	const renderer = ({ days, hours, minutes, seconds, completed }) => {
		if (completed) {
			return <span></span>
		} else {
			return <span>{days}d  {hours}h {minutes}m {seconds}s left</span>;
		}
	};
  }



  const renderer = ({ days, hours, minutes, seconds, completed }) => {
		if (completed) {
			return <span></span>
		} else {
			return <span>{days}d  {hours}h {minutes}m {seconds}s left</span>;
		}
	};






  return (



    <div className="home_header">
      <PurchaseNowRef
        ref={PurchaseNowForwardRef}
      />
      <PutOnSaleRef
        ref={PutOnSaleForwardRef}
        Refresh_page={Refresh_page}
      />
      <BurnRef
        ref={BurnForwardRef}
        Refresh_page={Refresh_page}
      />
      <CancelOrderRef
        ref={CancelOrderForwardRef}
        Refresh_page={Refresh_page}
      />
      <ReportNowRef
        ref={ReportForwardRef}
      />
      <LikeRef
        ref={LikeForwardRef}
        setLikedTokenList={setLikedTokenList}
      />
      <ShareNowRef
        ref={ShareForwardRef}
      />
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<Link to="/">
          <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" /></Link>}
        leftLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "white"
        }}
        
        {...rest}
        
      />
        <ScrollToTopOnMount />
      <section className="body-top">
      
       
         <section className="latestdropss navtabs pb-5 tabsheight" id="explore">
         <div className="container pt-5">
          <div className="topcreationlist pt-5">
            <p>NFT MARKETPLACE</p>
            <h2>LATEST DROPS</h2>
            <p className="latestdropdes">We connect passionate fans  with 100% We connect passionate fans  with 100% <a href="/how-it-works">&nbsp; Learn More</a></p>
          </div>
          <img src={bannerline} alt="..." className="bannerline"/>
          <div className="d-flex align-items-center overflow-hidden mt-5 flex_block_mob justify-content-center mb-5">

            <nav className="masonry_tab_nav items_tab_outer">
              <div className="nav nav-tabs masonry_tab primary_tab items_tab  items_tab_new pb-2 pl-2" id="nav-tab" role="tablist">
                <a className={CatName == 'All'?"nav-link active":"nav-link"} onClick={() => catChange('All')} id="collectibles-tab" data-toggle="tab" href="#collectibles" role="tab" aria-controls="collectibles" aria-selected="false">
                  All items
                </a>
                {
                  Categorylist.map((item) => {
                    return (
                      <a className={CatName ==item.name ?"nav-link active":"nav-link"} onClick={() => catChange(item.name)} data-tabname={item.name} id={item.name + "tab"} data-toggle="tab" role="tab" aria-controls="all" aria-selected="true" key={Math.random()}> {item.name}</a>
                    )
                  })
                }
              </div>
            </nav>
          </div>
          <div className="tab-content explore_tab_content mt-0" id="nav-tabContent">
            <div className="tab-pane fade show active" id="collectibles" role="tabpanel" aria-labelledby="collectibles-tab">
              <div className="mt-3">
                <div className=" m-0">
                  <div className="row">

                    {
                      (CatBasedTokenList && CatName && CatBasedTokenList[CatName] && CatBasedTokenList[CatName].list && CatBasedTokenList[CatName].list.length > 0) ? (CatBasedTokenList[CatName].list.map((item) => {
                        return (
                          <div className="col-lg-4 col-xl-3 col-sm-6 col-md-6 col-12" key={Math.random()}>
                            <TokenCard
                              item={item}
                              LikedTokenList={LikedTokenList}
                              hitLike={LikeForwardRef.current && LikeForwardRef.current.hitLike}
                              PutOnSale_Click={PutOnSaleForwardRef.current && PutOnSaleForwardRef.current.PutOnSale_Click}
                              PurchaseNow_Click={PurchaseNowForwardRef.current && PurchaseNowForwardRef.current.PurchaseNow_Click}
                              Burn_Click={BurnForwardRef.current && BurnForwardRef.current.Burn_Click}
                              CancelOrder_Click={CancelOrderForwardRef.current && CancelOrderForwardRef.current.CancelOrder_Click}
                              SubmitReport_Click={ReportForwardRef.current && ReportForwardRef.current.SubmitReport_Click}
                              ShareSocial_Click={ShareForwardRef.current && ShareForwardRef.current.ShareSocial_Click}
                            />
                          </div>
                        )
                      })) :

                        <div className="text-center py-5 col-12">
                          <div className="text-center py-3  no_items_row">
                            <p className="not_found_text">No items found</p>
                            <p className="not_found_text_sub">Come back soon!</p>
                            <div className="mt-3">
                            </div>
                          </div>
                        </div>
                    }



                  </div></div>
              </div>

            </div>
            {
              (CatBasedTokenList && CatBasedTokenList.loader == false && CatBasedTokenList[CatName] && CatBasedTokenList[CatName].onmore == true) ? (
                <div className="text-center mt-4 w-100 ">
                  {(CatBasedTokenList.loader == true) ? <i className="fa fa-spinner spinner_icon spinner_red" aria-hidden="true" id="spin"></i> : ('')}
                  <div id="spinBut">
                    <Button className="create_btn create_newbtnload mt-3 mx-auto btn-block" id="onmore" onClick={onLoadMore} style={{ display: "flex" }}>
                      Load More
                    </Button>
                  </div>
                </div>) : ('')}
          </div>



        </div>
         </section>

      
      
      
      
      
   
      
         
     
      </section>
   
     
     

      

      <Footer />

    </div>


  );
}
