/*eslint-disable*/
import React,{useState,useEffect} from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";
import {sociallinksfunction} from '../../actions/v1/report';
import { Link,useLocation } from "react-router-dom";

import { useSelector, useDispatch } from 'react-redux'

import { subscriberAction } from "actions/v1/token";
import { toast } from 'react-toastify';

const useStyles = makeStyles(styles);

export default function Footer(props) {
 
  const classes           =  useStyles();
  const Wallet_Details    =  useSelector(state => state.wallet_connect_context);

  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const[sociallinks,setsociallinks]=useState([])
  const[Footer_Link,Set_Footer_link]=useState([])
  const [mail, setmail] = useState('');
  
  
  useEffect(()=>{
    sociallinksfunct()
    },[])
  
  const sociallinksfunct=async()=>{
    var soci= await sociallinksfunction()
    if(soci&&soci.data&&soci.data.soci&&soci.data.soci[0]&&soci.data.soci[0].social.length>0){
     
      setsociallinks(soci.data.soci[0].social)
    }
    else{

      setsociallinks([])
    }
  }
 
  const toggleUsermenu = () => {
    var useclass = document.getElementsByClassName("usemneu_dd");
    for(var i=0;i<useclass.length;i++)
    {
      useclass[i].classList.toggle('d-none')
    } 
  }



  const mailstate = (e) => {
   
//console.log("Mialllll",data)
    setmail(e.target.value)
  }


  const newmailadd = async () => {
     console.log("mail>>>>",mail);
     let emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([A-Za-zA-Z\-0-9]+\.)+[A-Za-zA-Z]{2,}))$/;
     if (mail != "") {
      if (!emailRegex.test(mail)) {
        return toast.error("Enter valid email")
      }
    }
    var newmailData = await subscriberAction({ email: mail });
    console.log("newmailData",newmailData)
    if (newmailData && newmailData.data && newmailData.data && newmailData.data.success && newmailData.data.success == true) {
  
      toast.success(newmailData.data.message);
      setmail('');
    }
    console.log(">>>>newmailData",newmailData);
  }
  
 
  var location=useLocation();
  return (
    <div>
   
    <footer className={footerClasses}>
    <div className="container">
    <div className="row pb-4">
        <div className="col-12 col-md-12 col-lg-3 col-xl-3 mt-3 mt-lg-0 left-footer-sec">
            <a href="/">
                <span className="img-fluid logo_przn" alt="Shape"></span>
            </a>
            <div className="footerfollow">
            <p className="footer_big_text">Follow Us :</p>
            <div className="linksfoot">
             
           <a href=""><i class="fab fa-facebook-f"></i></a>
           <a href=""><i class="fab fa-twitter"></i></a>
           <a href=""><i class="fab fa-discord"></i></a>
        {/* <Link to={"/"+sociallinks[1].facebook}><i class="fab fa-facebook-f"></i></Link> */}
            </div>
            </div>
         
        </div>
        <div className="col-12 col-md-12 col-lg-9 col-xl-9 mt-3 mt-lg-0">
            <div className="row footer_row_new">
                <div className="col-12 col-md-4 col-lg-2 col-xl-2 mt-3 mt-lg-4">
                 
                    <ul className="footer_ul">
                      {Wallet_Details.UserAccountAddr==""?
                        <li >
                            <Link to="#" data-toggle="modal" data-target="#connect_modal" >Explore</Link>
                        </li>
                        :
                         <li >
                           <Link to="/explorePage"><span >Explore</span>
                           </Link>
                        
                     </li>}
                        <li>
                        <Link to="/aboutus">About Us</Link>
                        </li>
                    </ul>
                </div>
                <div className="col-12 col-md-4 col-lg-2 col-xl-2 mt-3 mt-lg-4">
                   
                    <ul className="footer_ul">
                     
                       
                         
                            
                        <li><Link to="/how-it-works">How It Works</Link></li>
                       
                        <li>
                            <Link to="/privacy-policy">Terms</Link>
                        </li>
                    </ul>
                </div>
                <div className="col-12 col-md-4 col-lg-2 col-xl-2 mt-3 mt-lg-4">
                   
                    <ul className="footer_ul">
                    <li ><Link to="/explorePage"><span >Marketplace</span></Link>
                         
                        
                     </li>
                        <li><Link to="/activity">Activity</Link></li>
                    </ul>
                </div>
               

                <div className="col-12 col-md-12 col-lg-6 col-xl-6 mt-3 mt-lg-4">
                    <p className="footer_heade">NewsLetter</p>
                    <p className="footer_para">Sign up for exclusive news, updates, & freebies on the latest 
NFT trends.</p>
                    <ul className="footer_ul">
                    {/* <Link to="#" target="_blank">Twiiter</Link>     
                    <Link to="#" target="_blank">Instagram</Link>    
                  
                    <Link to="#" target="_blank">Facebook</Link>         */}
                    {/* {sociallinks && sociallinks.length != 0 && sociallinks.map((list) => {
                        return(
                                
                            <li key={Math.random()}>
                            <a href={Object.values(list).toString()} target="_blank">{Object.keys(list)}</a>           
                            </li> 
                       )
                        })
                        }    */}
                        </ul>
                        <form action="" className="newslerter w-100 w-md-75">
          <input type="email"  maxlength="50" required placeholder="Enter your email address" onChange={mailstate} />
          <Button class="bt mr-2 mr-md-2" id="news_submit" onClick={() => { newmailadd() }} >Subscribe</Button>



        </form>
                        {/* </div> */}
                  </div>
                
                     
                    </div>
                </div>
               
             
           
                </div>
               
            </div>
    </footer>
    <hr className="hr_hrey my-0"></hr>
                <footer className="jss58 py-0 mt-0 footer_bottom">
                  <div className="container">
                    <div className="footerbottom py-4 justify-content-center">
                    
                        <p className="copyright_txt mb-md-0 text-center">
                          <span className="mb-0 text-white font_w_600 font_14">© 2022 OG2D.COM-Original2Digital NFT Marketplace.All Rights Reserved.</span></p>
                        {/* <p className="copyright_txt mb-md-0">We use cookies for better service.Accept</p> */}
                    
                        </div>
                        </div>
                        </footer>
</div>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
