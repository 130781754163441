import React, { useEffect,useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, TextField } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
// import HeaderLinksCommunity from "components/Header/HeaderLinksCommunity.js";

import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link,useLocation } from "react-router-dom";
// import HeaderLinks from "components/Header/HeaderLinks";
import {getPrivacyVal} from '../actions/v1/report';

import Founderone from "assets/images/founder1.jpg";
import Foundertwo from "assets/images/founder2.jpg";
import Founderthree from "assets/images/founder3.jpg";
import Founderfour from "assets/images/founder4.jpg";
import Founderfive from "assets/images/founder5.jpg";
import Foundersix from "assets/images/founder6.jpg";
import ReactHTMLParser from 'react-html-parser'
const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Privacypolicy(props) {
  
  const classes = useStyles();
  const { ...rest } = props;
  const location =useLocation();
  const[contents,setContents]=useState({})
  var location_name = ( location.pathname == "/privacy-policy" ) ? "privacy" : "aboutus";

  useEffect(()=>{
    getPrivacy();
  },[])

  const getPrivacy=async()=>{

    var reqdata={
      location:location_name
    }
    var test=await getPrivacyVal(reqdata)    
    if(test&&test.data&&test.data.userValue){
      setContents(test.data.userValue)
    }

  }

  return (
    <div className="inner_header">
     <div className={classes.pageHeader + " inner_pageheader1 pt-0"}>
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<Link to="/">
          <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid"/></Link>}
        leftLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount/>
      <div className={classes.pageHeader + "inner_pageheader inner_pageheader1"}>
        
        <div className="container mt-0 cms_content pb-4">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <div className="meAdded">  {ReactHTMLParser(contents.answer)} </div>
                      </GridItem>
          </GridContainer>
        </div>
      </div>
      <div className="founder">
      <div className="container_team mt-0 cms_content pb-4">
        <h3 className="ourteam">Our Team</h3>
        <h4 className="meetfounder">Meet the Founders</h4>
        <div className="row">
            <div className="col-xl-2 col-lg-4 col-sm-6 col-md-4 col-12 mb-sm-5 mb-3">
                <div className="found_1">
                    <img src={Founderone} alt="Paul Caldwell" className="img-fluid"/>
                    <div className="foundername">
                    <p>CHAIRMAN</p>
                      <h2>Paul Caldwell</h2>
                      
                    </div>
                </div>
            </div>
            <div className="col-xl-2 col-lg-4 col-sm-6 col-md-4 col-12 mb-sm-5 mb-3">
                <div className="found_1">
                    <img src={Foundertwo} alt="Paul Caldwell" className="img-fluid"/>
                    <div className="foundername">
                    <p>PRESIDENT & CEO</p>
                      <h2>Daryl Snyder</h2>
                      
                    </div>
                </div>
            </div>
            <div className="col-xl-2 col-lg-4 col-sm-6 col-md-4 col-12 mb-sm-5 mb-3">
                <div className="found_1">
                    <img src={Founderthree} alt="Paul Caldwell" className="img-fluid"/>
                    <div className="foundername">
                    <p>CTO</p>
                      <h2>Matthew Greene</h2>
                      
                    </div>
                </div>
            </div>
            
       
            <div className="col-xl-2 col-lg-4 col-sm-6 col-md-4 col-12 mb-sm-5 mb-3">
                <div className="found_1">
                    <img src={Founderfour} alt="Paul Caldwell" className="img-fluid"/>
                    <div className="foundername">
                    <p>CREATIVE DIRECTOR</p>
                      <h2>Eric Ng</h2>
                      
                    </div>
                </div>
            </div>
            <div className="col-xl-2 col-lg-4 col-sm-6 col-md-4 col-12 mb-sm-5 mb-3">
                <div className="found_1">
                    <img src={Founderfive} alt="Paul Caldwell" className="img-fluid"/>
                    <div className="foundername">
                    <p>STRATEGIC BUSINESS ADVISOR</p>
                      <h2>Money-B</h2>
                      
                    </div>
                </div>
            </div>
            <div className="col-xl-2 col-lg-4 col-sm-6 col-md-4 col-12 mb-sm-5 mb-3">
                <div className="found_1">
                    <img src={Foundersix} alt="Paul Caldwell" className="img-fluid"/>
                    <div className="foundername">
                    <p>INTELLECTUAL PROPERTY LAW</p>
                      <h2>Brett T. Cooke</h2>
                      
                    </div>
                </div>
            </div>
            
        </div>
        </div>
</div>
      </div>
     
      <Footer/>
    </div>

    

  );
}
