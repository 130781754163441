

import React, { useEffect, useState, useRef } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Link, useHistory,useParams } from "react-router-dom";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Button } from "@material-ui/core";
import bannerline from "assets/images/bannerline.png";
import { useSelector,useDispatch } from 'react-redux'

// FILES
import {CollectiblesList_Home} from '../actions/v1/token'
import TokenCard from '../views/separate/TokenCard'
import axios from 'axios';
import config from '../lib/config';
import { CancelOrderRef } from './separate/CancelOrderRef';
import { LikeRef } from './separate/LikeRef';
import { BurnRef } from './separate/BurnRef';
import { PutOnSaleRef } from './separate/PutOnSaleRef';
import { PurchaseNowRef } from './separate/PurchaseNowRef';
import { ReportNowRef } from './separate/ReportNowRef';
import { ShareNowRef } from './separate/ShareNowRef';


let toasterOption = config.toasterOption;
const dashboardRoutes = [];
const useStyles = makeStyles(styles);

export default function Explores(props){

    const classes = useStyles();
    const history = useHistory();
  	const {Param_category} = useParams()
    const Wallet_Details = useSelector(state => state.wallet_connect_context);
    const dispatch=useDispatch();
    const { ...rest } = props;
    var LikeForwardRef = useRef();
    var PutOnSaleForwardRef = useRef();
    var PurchaseNowForwardRef = useRef();
    var BurnForwardRef = useRef();
    var CancelOrderForwardRef = useRef();
    var ReportForwardRef = useRef();
    var ShareForwardRef = useRef();
    var PlaceABidForwardRef = useRef();
    const [Explore_list, Set_Explore_List] = useState([]);
  const [LikedTokenList, setLikedTokenList] = useState([]);

    useEffect(()=>{
      
      Explore_Category()
    },[Param_category])
    const Refresh_page=()=>{

    }


    function ScrollToTopOnMount() {
      useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      return null;
    }
    

    const Explore_Category = async () => {
      var currAddr = Wallet_Details.UserAccountAddr;
      var payload = {
        category:Param_category,
        limit: config.limitMax,
         from: 'explore_category', 
         currAddr: currAddr, 
      }
      var resp = await CollectiblesList_Home(payload);
      if (resp && resp.data && resp.data.from == 'explore-category-token-collectibles-list-home' && resp.data.list && resp.data.list.length > 0) {
        Set_Explore_List(resp.data.list)
      }
      else {
        Set_Explore_List([])
      }
    }
  
return(
<div className="home_header explore_s">
<PurchaseNowRef
        ref={PurchaseNowForwardRef}
        />
        <PutOnSaleRef
        ref={PutOnSaleForwardRef}
        Refresh_page={Refresh_page}       
        />
        <BurnRef
        ref={BurnForwardRef}
        Refresh_page={Refresh_page}
        />
        <LikeRef
          ref={LikeForwardRef}
          setLikedTokenList={setLikedTokenList}
        />
        <CancelOrderRef
          ref={CancelOrderForwardRef}
          Refresh_page={Refresh_page}
        />
        <ReportNowRef
          ref={ReportForwardRef}
        />
        <ShareNowRef
          ref={ShareForwardRef}
        />
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand={<Link to="/">
            <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" /></Link>}
          leftLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 20,
            color: "white"
          }}
          {...rest}
        />   
         <ScrollToTopOnMount /> 
<section className="navtabs pb-5 tabsheight background-explote">
<div className="container mt-5">
  <div className="topcreationlist pt-5">
    <h2>{Param_category}</h2>
    <img src={bannerline} alt="..." className="bannerline"/>
  </div>
  
  <div className="tab-content explore_tab_content mt-0" id="nav-tabContent">
    <div className="tab-pane fade show active" id="collectibles" role="tabpanel" aria-labelledby="collectibles-tab">
      <div className="mt-3">
        <div className=" m-0">
          <div className="row">
            {console.log("param_category ",Param_category)}
        {Explore_list.length!=0?
         
                   Explore_list.map((item)=>{
                    return     <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
                  <TokenCard
                      item={item}
                      LikedTokenList={LikedTokenList}
                      hitLike={LikeForwardRef.current && LikeForwardRef.current.hitLike}
                      PutOnSale_Click={PutOnSaleForwardRef.current && PutOnSaleForwardRef.current.PutOnSale_Click}
                      PurchaseNow_Click={PurchaseNowForwardRef.current && PurchaseNowForwardRef.current.PurchaseNow_Click}
                      Burn_Click={BurnForwardRef.current && BurnForwardRef.current.Burn_Click}
                      CancelOrder_Click={CancelOrderForwardRef.current && CancelOrderForwardRef.current.CancelOrder_Click}
                      SubmitReport_Click={ReportForwardRef.current && ReportForwardRef.current.SubmitReport_Click}
                      ShareSocial_Click={ShareForwardRef.current && ShareForwardRef.current.ShareSocial_Click}
                    />
                    </div>
                   })
             
:
                <div className="text-center py-5 col-12">
                  <div className="text-center py-3  no_items_row">
                    <p className="not_found_text">No items found</p>
                    <p className="not_found_text_sub">Come back soon! Or try to browse something for you on our marketplace</p>
                    <div className="mt-3">
                    </div>
                  </div>
                </div>
            
                }


          </div></div>
      </div>

    </div>
 
  </div>



</div>
</section>
   <Footer />
</div>
)}